import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import headerbg from '../../assets/images/bgheader.jpg'

function TitleBreadcrumbs(props) {
  const pageHeaderStyle = {
    backgroundImage: 'url(' + headerbg + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <section className="page-header" style={pageHeaderStyle}>
      <Container>
        <Row>
          <Col sm={12}>
            <h1>{props.name}</h1>
            <ul className="list-unstyled">
              <li><a href="/">Inicio</a></li>
              <li className="active">{props.name}</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TitleBreadcrumbs;
