import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

import ErrorMessageMultiple from "./ErrorMessageMultiple.js";
import { API } from "../../services/Api.js";
import { file2base64 } from "../../services/file2base64.js";

export default function HireElectricityForm() {
  const [sending, setSending] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: {
      errors,
      isDirty,
      isSubmitSuccessful,
      isValid,
    },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues: {
      "installation": "U",
      "self_consumption_type": "U",
      "self_consumption_power": "",
      "self_consumption_situation": "U",
      "self_consumption_installation": "U",
      "self_consumption_extra": "U",
      // TESTING
      //"cups": "ES1231231231231231XXOF",
      //"have_electricity": "N",
      //"supply_address": "Calle Ejemplo",
      //"supply_zipcode": "21007",
      //"supply_province": "Province",
      //"supply_municipality": "Municipality",
      //"is_house": "Y",
      //"cnae": "9820",
      //"contract_power": "L",
      //"peak_hour": "10kW",
      //"valley_hour": "15kW",
      //"is_self_consumption": "N",
      //"cau": "",
      //"name": "Name",
      //"surname": "Surname",
      //"dni": "12312312K",
      //"address": "Calle Ejemplo",
      //"zipcode": "21007",
      //"province": "Provincia",
      //"municipality": "Municipio",
      //"email": "me@candelacoop.com",
      //"confirmEmail": "me@candelacoop.com",
      //"phone": "666666666",
      //"iban": "ES9121000418450200051332",
      //"direct_debit": true,
      //"participate": "N",
      //"policy": true,
    },
  });

  const email = useWatch({control, name: "email"})
  const have_electricity = useWatch({control, name: "have_electricity"})
  const is_self_consumption = useWatch({control, name: "is_self_consumption"})

  const handleInputFile = (e) => {
    file2base64(e.target.files[0])
      .then(({mimetype, base64}) => {
        setValue("lastContractFile", base64);
        setValue("lastContractMimetype", mimetype);
      }).catch((err) => {
        setErrorMessages(err)
      })
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setSending(true);
    setSuccessMessage("")
    setErrorMessages([])

    API.members.hire(data)
      .then((response) => {
        setSuccessMessage("¡Contratación en curso!\nEn breve recibirás un correo electrónico con más información.\nGracias por unirte al cambio energético")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return error.response.json();
        }
        if (error.response.status === 403) {
          return {"": ["No tiene permisos para hacer eso"]}
        }
        return {"": [error]}
      })
      .then((errJson) => {
        let errors = [];
        errJson && Object.entries(errJson).map(([field, msgs]) => {
          let msg = msgs;
          if (Array.isArray(msgs)) {
            msg = msgs[0]
          }
          errors.push({field: field, message: msg})
        })
        setErrorMessages(errors)
      })
      .finally(() => setSending(false))
  }

  useEffect(() => {
    if (isSubmitSuccessful && isValid && successMessage) {
      reset({
        "cups": "",
        "have_electricity": "U",
        "installation": "U",
        "supply_address": "",
        "supply_zipcode": "",
        "supply_province": "",
        "supply_municipality": "",
        "is_house": "U",
        "cnae": "",
        "contract_power": "U",
        "peak_hour": "",
        "valley_hour": "",
        "is_self_consumption": "U",
        "cau": "",
        "self_consumption_type": "U",
        "self_consumption_power": "",
        "self_consumption_situation": "U",
        "self_consumption_installation": "U",
        "self_consumption_extra": "U",
        "name": "",
        "surname": "",
        "dni": "",
        "address": "",
        "zipcode": "",
        "province": "",
        "municipality": "",
        "email": "",
        "confirmEmail": "",
        "phone": "",
        "iban": "",
        "direct_debit": false,
        "participate": "U",
        "policy": false,
      })
    }
    if (!isSubmitSuccessful && !isValid && isDirty) {
      setSuccessMessage("")
    }
  }, [isSubmitSuccessful, isValid, successMessage, isDirty])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h3>Datos necesarios (obligatorios)</h3>

      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="cups">CUPS (Código Universal del Punto de Suministros)</label>
          <input id="cups" type="text" {...register("cups", {
            required: "Campo obligatorio",
            maxLength: {
              value: 22,
              message: "Campo demasiado largo",
            },
            pattern: {
              value: /^ES[0-9]{16}[A-Za-z]{2}(OF)?$/i,
              message: "Formato inválido",
            },
})} />
          <small className="form-text text-muted">
            <pre>
              Identifica los puntos de suministros. Si cambias de vivienda, dejarás de tener el CUPS que tenías hasta
              ahora en tu factura, y en el nuevo hogar tendrás uno distinto.<br/>
              Está compuesto por 22 caracteres, siempre empieza por "ES" seguido de 16 números,
              2 letras y termina en "OF". Lo podrás encontrar en cualquier factura, ya que es
              obligatorio que aparezca, aunque algunas comercializadoras omiten el "OF" del final.
            </pre>
          </small>
          <ErrorMessageMultiple errors={errors} name="cups" />
        </Col>

        <Col sm={6}>
          <label htmlFor="have_electricity">¿Hay luz?</label>
          <select id="have_electricity" {...register("have_electricity", {
            validate: {
              valid: val => ["Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="have_electricity" />
        </Col>

        { have_electricity === "Y" &&
        <Col sm={6}>
          <label htmlFor="installation">¿Qué tipo de instalación tienes?</label>
          <select id="installation" {...register("installation", {
            validate: {
              valid: val => ["M", "T"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="M">Monofásica</option>
            <option value="T">Trifásica</option>
          </select>
          <small className="form-text text-muted">
            <pre>
              Si observas cómo son tus protecciones o interruptores principales del cuadro eléctrico podrás saber si tu instalación es monofásica o trifásica<br/>
                - MONOFÁSICA: Llegan 2 cables.<br/>
                - TRIFÁSICA: Llegan 4 cables.<br/>
              Tanto en un tipo de instalación como en el otro, puede que haya un cable más, de color amarillo-verde, es la toma de tierra.
            </pre>
          </small>
          <ErrorMessageMultiple errors={errors} name="installation" />
        </Col>
        }
        <Col sm={6}>
          <label htmlFor="birthdate">Contrato del último mes</label>
          <Form.Control id="lastContract" size="lg" type="file" onChange={e => handleInputFile(e)} accept="application/pdf" required />
          <ErrorMessageMultiple errors={errors} name="lastContract" />
        </Col>
      </Row>

      <h3>Datos del punto de suministro (obligatorios)</h3>
      <Row>
        <Col sm={6}>
          <label htmlFor="supply_address">Dirección</label>
          <input id="supply_address" type="text" autoComplete="address" {...register("supply_address", {
            required: "Campo obligatorio",
            maxLength: {
              value: 120,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="supply_address" />
        </Col>
        <Col sm={6}>
          <label htmlFor="supply_zipcode">Código postal</label>
          <input id="supply_zipcode" type="text" autoComplete="zipcode" {...register("supply_zipcode", {
            required: "Campo obligatorio",
            pattern: {
              value: /^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$/i,
              message: "Formato inválido",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="supply_zipcode" />
        </Col>
        <Col sm={6}>
          <label htmlFor="supply_province">Provincia</label>
          <input id="supply_province" type="text" autoComplete="city" {...register("supply_province", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="supply_province" />
        </Col>
        <Col sm={6}>
          <label htmlFor="supply_municipality">Municipio</label>
          <input id="supply_municipality" type="text" autoComplete="city" {...register("supply_municipality", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="supply_municipality" />
        </Col>

        <Col sm={6}>
          <label htmlFor="is_house">¿Es una vivienda?</label>
          <select id="is_house" {...register("is_house", {
            validate: {
              valid: val => ["U", "Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="is_house" />
        </Col>

        <Col sm={6}>
          <label htmlFor="cnae">CNAE (Clasificación Nacional de Actividades Económicas)</label>
          <input id="cnae" type="text" {...register("cnae", {
            required: "Campo obligatorio",
            pattern: {
              value: /^[0-9]{4}$/i,
              message: "Formato inválido",
            },
})} />
          <small className="form-text text-muted">
            <pre>
              Este dato es necesario ya que, como empresa comercializadora, tenemos que comunicarlo a la empresa
              distribuidora al realizar un alta de suministro o cambio de compañía.<br/>
              Para viviendas el código es 9820. Otros códigos habituales son:<br/>
              - 4711: Comercio al por menor en establecimientos no especializados.<br/>
              - 5510: Hoteles y alojamientos similares.<br/>
              - 6820: Alquiler de bienes inmobiliarios por cuenta propia.<br/>
              - 8411: Actividades generales de la Administración Pública.<br/>
              - 8520: Educación Primaria.<br/>
              - 9499: Otras actividades asociativas no clasificadas anteriormente
            </pre>
          </small>
          <ErrorMessageMultiple errors={errors} name="cnae" />
        </Col>

        <Col sm={6}>
          <label htmlFor="contract_power">¿Qué potencia tienes contratada o quieres contratar?</label>
          <select id="contract_power" {...register("contract_power", {
            validate: {
              valid: val => ["L", "H"].includes(val) || "Campo obligatorio",
              restriction: val => ["U", "L"].includes(val) || "Para contratar una potencia superior a 15 kW debe contactar con nosotros en info@candelacoop.com",
            },
          })}>
            <option value="U" default>--</option>
            <option value="L">Potencia inferior o igual a 15 kW</option>
            <option value="H">Potencia superior a 15 kW</option>
          </select>
          <small className="form-text text-muted">
            <pre>
              Para evitar problemas de tramitación, pon la misma potencia que aparece en la factura actual.
              Si en tu factura solamente aparece un periodo de potencia, deberás indicar la misma en los dos apartados.
              Podrás solicitar un cambio de potencia una vez esté el contrato activado.<br/>
              <b>Si quieres contratar más de 15 kW, tienes que contactar con nosotros en info@candelacoop.com.</b>
            </pre>
          </small>
          <ErrorMessageMultiple errors={errors} name="contract_power" />
        </Col>

        <Col sm={6}>
          <label>Como consecuencia del cambio de peajes y tarifas propuesto por la CNMC, ahora debes indicar 2 periodos de potencia</label>
          <div>
            <label htmlFor="peak_hour">Punta</label>
            <input id="peak_hour" type="text" {...register("peak_hour", {
              required: "Campo obligatorio",
              maxLength: {
                value: 80,
                message: "Campo demasiado largo",
              },
  })} />
            <ErrorMessageMultiple errors={errors} name="peak_hour" />
          </div>
          <div>
            <label htmlFor="valley_hour">Valle</label>
            <input id="valley_hour" type="text" {...register("valley_hour", {
              required: "Campo obligatorio",
              maxLength: {
                value: 80,
                message: "Campo demasiado largo",
              },
  })} />
            <ErrorMessageMultiple errors={errors} name="valley_hour" />
          </div>
        </Col>

        <Col sm={6}>
          <label htmlFor="is_self_consumption">¿Tienes alguna modalidad de autoconsumo?</label>
          <select id="is_self_consumption" {...register("is_self_consumption", {
            validate: {
              valid: val => ["U", "Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="is_self_consumption" />
        </Col>
      </Row>

      { is_self_consumption === "Y" &&
        <div>
          <h3>Datos de la instalación de autoconsumo (obligatorios)</h3>

          <Row>

            <Col sm={6}>
              <label htmlFor="cau">Código CAU (Código de Autoconsumo Unificado)</label>
              <input id="cau" type="text" {...register("cau", {
                required: "Campo obligatorio",
                minLength: {
                  value: 26,
                  message: "Campo demasiado corto. CAU tiene 26 dígitos",
                },
                maxLength: {
                  value: 26,
                  message: "Campo demasiado largo. CAU tiene 26 dígitos",
                },
              })} />
              <small className="form-text text-muted">
                <pre>
                  Se utiliza para registrar la instalación de autoconsumo con compensación de excedentes (tanto individual
                  como colectiva) y consta de una serie de letras y números que sirven para identificar la instalación,
                  saber dónde se ubica y dónde se genera la energía. Para saber cuál es el de tu instalación, tienes que
                  contactar con la empresa distribuidora eléctrica de tu zona.
                </pre>
              </small>
              <ErrorMessageMultiple errors={errors} name="cau" />
            </Col>

            <Col sm={6}>
              <label htmlFor="self_consumption_type">¿Cómo es tu instalación de autoconsumo?</label>
              <select id="self_consumption_type" {...register("self_consumption_type", {
                required: "Campo obligatorio",
                validate: {
                  valid: val => ["I", "C"].includes(val) || "Campo obligatorio",
                },
              })}>
                <option value="U" default>--</option>
                <option value="I">Individual</option>
                <option value="C">Colectivo</option>
              </select>
              <ErrorMessageMultiple errors={errors} name="self_consumption_type" />
            </Col>

            <Col sm={6}>
              <label htmlFor="self_consumption_power">¿Qué potencia tiene tu instalación de autoproducción?</label>
              <input id="self_consumption_power" type="text" {...register("self_consumption_power", {
                required: "Campo obligatorio",
    })} />
              <small className="form-text text-muted">
                <pre>
                  Esta información la tienes disponible en el certificado de instalación eléctrica que la empresa técnica que
                  te ha realizado la instalación te debería haber proporcionado. Este dato suele ubicarse en el apartado
                  "características técnicas de la instalación" y encontrarás el concepto indicado como "potencia instalada".
                </pre>
              </small>
              <ErrorMessageMultiple errors={errors} name="self_consumption_power" />
            </Col>

            <Col sm={6}>
              <label htmlFor="self_consumption_situation">Situación de la instalación</label>
              <select id="self_consumption_situation" {...register("self_consumption_situation", {
                required: "Campo obligatorio",
                validate: {
                  valid: val => ["L", "N", "I"].includes(val) || "Campo obligatorio",
                },
              })}>
                <option value="U" default>--</option>
                <option value="L">Instalación de enlace</option>
                <option value="N">Próxima a través de la red</option>
                <option value="I">Red interior</option>
              </select>
              <ErrorMessageMultiple errors={errors} name="self_consumption_situation" />
            </Col>

            <Col sm={6}>
              <label htmlFor="self_consumption_installation">Tipo de instalación</label>
              <select id="self_consumption_installation" {...register("self_consumption_installation", {
                required: "Campo obligatorio",
                validate: {
                  valid: val => ["1", "2", "O"].includes(val) || "Campo obligatorio",
                },
              })}>
                <option value="U" default>--</option>
                <option value="1">B11</option>
                <option value="2">B21</option>
                <option value="O">Otras instalaciones</option>
              </select>
              <small className="form-text text-muted">
                <pre>
                  - B11: Instalaciones que únicamente utilicen la radiación solar como energía primaria mediante la tecnología
                  fotovoltaica.<br/>
                  - B21: Instalaciones eólicas ubicadas en tierra.
                </pre>
              </small>
              <ErrorMessageMultiple errors={errors} name="self_consumption_installation" />
            </Col>

            <Col sm={6}>
              <label htmlFor="self_consumption_extra">¿Dispones de algún servicio auxiliar? (Ej. Baterías u otros acumuladores)</label>
              <select id="self_consumption_extra" {...register("self_consumption_extra", {
                validate: {
                  valid: val => ["U", "Y", "N"].includes(val) || "Campo obligatorio",
                },
              })}>
                <option value="U" default>--</option>
                <option value="Y">Sí</option>
                <option value="N">No</option>
              </select>
              <ErrorMessageMultiple errors={errors} name="self_consumption_extra" />
            </Col>
          </Row>
        </div>
      }

      <h3>Datos de la persona titular del contrato (obligatorios)</h3>
      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="name">Nombre</label>
          <input id="name" type="text" autoComplete="name" {...register("name", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="name" />
        </Col>
        <Col sm={6}>
          <label htmlFor="surname">Apellidos</label>
          <input id="surname" type="text" autoComplete="surname" {...register("surname", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="surname" />
        </Col>
        <Col sm={6}>
          <label htmlFor="dni">DNI</label>
          <input id="dni" type="text" {...register("dni", {
            required: "Campo obligatorio",
            pattern: {
              value: /^[0-9]{8}[A-Za-z]{1}$/i,
              message: "Formato inválido",
            }
          })} />
          <ErrorMessageMultiple errors={errors} name="dni" />
        </Col>

        <Col sm={6}>
          <label htmlFor="address">Dirección</label>
          <input id="address" type="text" autoComplete="address" {...register("address", {
            required: "Campo obligatorio",
            maxLength: {
              value: 120,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="address" />
        </Col>
        <Col sm={6}>
          <label htmlFor="zipcode">Código postal</label>
          <input id="zipcode" type="text" autoComplete="zipcode" {...register("zipcode", {
            required: "Campo obligatorio",
            pattern: {
              value: /^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$/i,
              message: "Formato inválido",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="zipcode" />
        </Col>

        <Col sm={6}>
          <label htmlFor="province">Provincia</label>
          <input id="province" type="text" autoComplete="city" {...register("province", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="province" />
        </Col>
        <Col sm={6}>
          <label htmlFor="municipality">Municipio</label>
          <input id="municipality" type="text" autoComplete="city" {...register("municipality", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="municipality" />
        </Col>
        <Col sm={6}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            autoComplete="email"
            {...register("email", {
              required: "Campo obligatorio",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Email no válido",
              },
            })} />
          <ErrorMessageMultiple errors={errors} name="email" />
        </Col>
        <Col sm={6}>
          <label htmlFor="confirmEmail">Confirmar email</label>
          <input id="confirmEmail" type="email" autoComplete="email" {...register("confirmEmail", {
            required: "Campo obligatorio",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email no válido",
            },
            validate: {
              confirm: confirmEmail => confirmEmail === email || "Email no son iguales",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="confirmEmail" />
        </Col>

        <Col sm={6}>
          <label htmlFor="phone">Teléfono</label>
          <input id="phone" type="phone" autoComplete="phone" {...register("phone", {
            required: "Campo obligatorio",
            maxLength: {
              value: 15,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="phone" />
        </Col>
      </Row>

      <h3>Datos de pago (obligatorios)</h3>
      <Row>
        <Col sm={6}>
          <label htmlFor="iban">IBAN</label>
          <input id="iban" autoComplete="iban" {...register("iban", {
            required: "Campo obligatorio",
            pattern: {
              value: /^ES[0-9]{22}$/i,
              message: "Formato inválido",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="iban" />
        </Col>
      </Row>

      <Row>
        <label className="checkbox">
          <input type='checkbox' {...register("direct_debit", {required: "Requerido"})} />
          <small>
            <p>
              Cómo titular de la cuenta bancaria, acepto la domiciliación de los recibos
            </p>
          </small>
        </label>
        <ErrorMessageMultiple errors={errors} name="direct_debit" />
      </Row>

      <Row>
        <Col sm={6}>
          <label htmlFor="participate">
            SOM y Candela S.C.A. son cooperativas sin ánimo de lucro con el objetivo firme de cambiar el modelo
            energético. Las socias y socios que lo deseen pueden realizar un donativo voluntario de 0,01 €/kWh, que
            serán transferidos a Candela S.C.A. para el desarrollo de los proyectos de las comunidades de la
            cooperativa, puesta en marcha de proyectos,etc. Para un consumo medio de una familia (aproximadamente 300
            kWh/mes) esto representa un incremento de 3 euros mensuales. ¿Quieres participar con tu energía para hacerlo
            posible?
          </label>
          <select id="participate" {...register("participate", {
            validate: {
              valid: val => ["Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="participate" />
        </Col>
      </Row>

      <Row>
        <label className="checkbox">
          <input type='checkbox' {...register("policy", {required: "Requerido"})} />
          <small>
            <p>
              Conozco y acepto la <Link target="_blank" to="/legal/politica-privacidad">política de privacidad</Link> y el <Link target="_blank" to="/legal/aviso-legal">tratamiento de datos</Link>.
            </p>
          </small>
        </label>
        <ErrorMessageMultiple errors={errors} name="policy" />
      </Row>

      <Row>
        <Col sm={4}>
          <button
            className="mb-4"
            type="submit"
          >
            { sending ? "Enviando ..." : "Enviar" }
          </button>
        </Col>
        <Col sm={8}>
          { errorMessages.length > 0 && (
            <div className="alert alert-danger">
              <span key="zero" >Error al validar el formulario:</span>
              { errorMessages.map((err, key) => <span key={key}><br/><b>{err.field}:</b> {err.message}</span>) }
            </div>
          )}
          { successMessage && <div className="alert alert-success">{successMessage}</div> }
        </Col>
      </Row>
    </Form>
  );
}
