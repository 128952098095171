import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { API, TOKEN_NAME } from "../../services/Api.js";
import '../../assets/css/styles.css';
import '../../assets/css/animate.css';
import '../../assets/css/responsive.css';
import '../../assets/css/slick.css';
import logo from '../../assets/images/logo.png';

function Header(props) {
  const [username, setUsername] = useState("");
  const [hires, setHires] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    API.members.me()
      .then((response) => {
        setUsername(response.username)
        setHires(response.hires)
      })
      .catch((error) => {
        if (username === "" && location.pathname === "/hire/") {
          navigate("/")
        }
      })
  }, [username, location.pathname])

  const disconnect = () => {
    localStorage.removeItem(TOKEN_NAME)
    setUsername("")
  }

  return (
    <React.Fragment>
      <header className="sticky-header">
        { username ? (
          <div className="header-top header-top-dark header-top-right">
            { hires < 2 ? (
              <Button as={Link} to="/hire/" className="apply-btn ms-2">Contrata Luz</Button>
            ) : (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover>
                    <Popover.Header>
                      <b>Máximo número de contratos permitidos</b>
                    </Popover.Header>
                    <Popover.Body>
                      Ahora mismo solo permitimos que cada socio/a pueda realizar un total de 2 contrataciones.
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button className="apply-btn ms-2">Contrata Luz</Button>
              </OverlayTrigger>
            )
            }
            <Dropdown className="ms-2">
              <Dropdown.Toggle className="apply-btn" id="dropdown-basic">
                {username}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item onClick={() => disconnect()} className="apply-btn">
                  <span className="apply-btn">Desconectar</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className="header-top header-top-dark header-top-right">
            <Dropdown>
              <Dropdown.Toggle className="apply-btn" id="dropdown-basic">
                Hazte socia/o
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item as={Link} eventKey={"1"} to="/asociate/fisica/" className="apply-btn">
                  <span className="apply-btn">Persona física</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={"2"} to="/asociate/juridica/" className="apply-btn">
                  <span className="apply-btn">Persona jurídica</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button as={Link} to="/login/" className="apply-btn ms-2">Iniciar Sesión</Button>
          </div>
        )
        }

      <Navbar id="main-nav" expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="Candela" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon icon={faBars} className="navbar-icon-bars" />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link as={Link} to="/"><span>INICIO</span></Nav.Link>
              <Nav.Link as={Link} to="/blog/"><span>NOTICIAS</span></Nav.Link>
              <Nav.Link as={Link} to="/quienes-somos/"><span>¿QUIÉNES SOMOS?</span></Nav.Link>
              <Nav.Link as={Link} to="/documentos/"><span>DOCUMENTOS</span></Nav.Link>
              { username && <Nav.Link as={Link} to="/asamblea/"><span>ASAMBLEA</span></Nav.Link> }
              <Nav.Link as={Link} to="/tarifas/"><span>TARIFAS</span></Nav.Link>
              <Nav.Link as={Link} to="/contacto/"><span>CONTACTO</span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      </header>
    </React.Fragment>
  );
}

export default Header;
