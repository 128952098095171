import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HireElectricityForm from '../components/HireElectricityForm';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter, faTelegramPlane, faInstagram } from '@fortawesome/free-brands-svg-icons';

import '../../assets/css/about.css';

library.add(faAngleUp, faFacebookF, faTwitter, faTelegramPlane, faInstagram);


function Hire(props) {
  document.title = `${props.name} | CandelaCoop`;

  return (
    <div className="Hire">
      <Header />

      <section className="hire-forms">
        <Container>
          <div className="section-header03">
            <h2>Formulario para autorizar la contratación de la luz con SOM Energía vinculada a Candela S.C.A.</h2>
            {/* FORM */}
            <HireElectricityForm />
          </div>
        </Container>
      </section>

      <Footer />

    </div>
  )

}

export default Hire
