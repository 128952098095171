import { useParams } from 'react-router-dom';
import data from '../../data/legaldata.json';

import NotFoundPage from '../pages/NoMatch';
import PageFromApi from "../layouts/PageFromApi.js";


export default function Legal() {
  const { slug } = useParams();

  if (!slug) {
    return <NotFoundPage/>;
  }

  return (
    <PageFromApi pagename={slug} />
  );
}
