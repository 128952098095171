import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import BlogLastNews from "../components/BlogLastNews";
import ReactPaginate from 'react-paginate';
import Sidebar from "../components/Sidebar";

export default class Blog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			data: [],
			perPage: 6,
			currentPage: 0
		};
		this.handlePageClick = this
			.handlePageClick
			.bind(this);
	}

	receivedData() {
		const baseURL = process.env.REACT_APP_API_URL

		fetch(baseURL + 'news/')
			.then(response => response.json())
			.then(jsonapi => {

				const data = jsonapi.data;
				const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
				const postData = slice.map(pd => <BlogLastNews key={pd.id} post={pd} />)

				this.setState({
					pageCount: Math.ceil(data.length / this.state.perPage),
					postData
				})
			});
	}
	handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;

		this.setState({
			currentPage: selectedPage,
			offset: offset
		}, () => {
			this.receivedData()
		});

	};

	componentDidMount() {
    document.title = `${this.props.name} | CandelaCoop`;
		this.receivedData()
	}

	render() {
		return (
		<div className="blog-news">
			<Header />
      <TitleBreadcrumbs name={this.props.name} />

			<section className="blog-wrapper blog">
				<Container>
					<Row>
						<Col md={9} sm={8}>
							<div className="blog-four-content">
								<div className="blog-posts">
									<Row>
										{this.state.postData && this.state.postData.length ? (
											<React.Fragment>
												{this.state.postData}
											</React.Fragment>
										) : (
											<div className="alert alert-warning">No se han encontrado noticias</div>
										)}
									</Row>
								</div>
							</div>
								{this.state.postData && this.state.postData.length && (
									<div className="blog-pagination">
										<ReactPaginate
											previousLabel={"<"}
											nextLabel={">"}
											breakLabel={"..."}
											breakClassName={"break-me"}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={this.handlePageClick}
											containerClassName={"list-unstyled"}
											subContainerClassName={"pages pagination"}
											activeClassName={"active"} />
									</div>
								)}
						</Col>
						<Col md={3} sm={4}>

							<Sidebar />

						</Col>
					</Row>
				</Container>
			</section>

			<Footer />

		</div>

		)
	}
}
