import useScript from '../../hooks/useScript';

function FooterScripts() {

	useScript('/assets/slick.min.js', true);
	useScript('/assets/plugins.js', false);
	useScript('/assets/custom.js', false);

	return (
		<>
		</>
	);
}

export default FooterScripts;
