import { Col, Container, Image, Row } from 'react-bootstrap';
import Header from '../components/Header';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import AwesomeSliderStyles from '../../assets/css/awesomesliderstyles.css';
import slidehome01 from '../../assets/images/slidehome01.jpg';
import slidehome02 from '../../assets/images/slidehome02.jpg';
import slidehome03 from '../../assets/images/candela-bicicletas.jpg';
import slidehome04 from '../../assets/images/candela-macetas.jpg';
import { HomeLastNewsCarrousel } from '../components/HomeLastNewsCarrousel';
import Footer from '../components/Footer';
import aboutimg from '../../assets/images/candela-girasoles.jpg';

function Home() {
	const AutoplaySlider = withAutoplay(AwesomeSlider);
	document.title = 'Inicio | CandelaCoop';

	return (
		<div className="Home">
			<Header />

			<AutoplaySlider animation="cubeAnimation"
				play={true}
				cancelOnInteraction={true} // should stop playing on user interaction
				interval={12000}
				cssModule={AwesomeSliderStyles}
				bullets={false}
			>
				<div data-src={slidehome01}>
					<div className="slider-item">
						<h1 className="slider-title">No más pobreza energética, la luz en nuestras manos</h1>
						<p className="slider-content"><span className="font-candela">candela</span>, <span className="text-handwrite">comunidad y justicia social</span></p>
					</div>
				</div>
				<div data-src={slidehome02}>
					<div className="slider-item">
						<h1 className="slider-title">Cuidar el sol, el aire, la tierra, el agua</h1>
						<p className="slider-content"><span className="font-candela">candela</span>, <span className="text-handwrite">la vida</span></p>
					</div>
				</div>
				<div data-src={slidehome03}>
					<div className="slider-item">
						<h1 className="slider-title">Dedica el tiempo a lo que importa. </h1>
						<p className="slider-content"><span className="font-candela">candela</span>, <span className="text-handwrite">las cosas sencillas</span></p>
					</div>
				</div>
				<div data-src={slidehome04}>
					<div className="slider-item">
						<h1 className="slider-title">Raíces comunes para un futuro habitable</h1>
						<p className="slider-content"><span className="font-candela">candela</span>, <span className="text-handwrite">un compromiso</span></p>
					</div>
				</div>
			</AutoplaySlider>

			<section className="about-ds">
				<Container>
					<Row>
						<Col md={7} sm={6} className="about-ds-content">
							<div className="section-header03">
								<h2>Bienvenidas a <span>Candela</span></h2>
							</div>
							<p>Recibe nuestra más afectuosa bienvenida. Nos encanta saber que tienes curiosidad por este tipo de iniciativas energéticas y que buscas información para decidirte de una vez a dar el salto hacia alternativas más justas y ecológicas en la producción eléctrica.</p>
							<p>Aquí encontrarás un espacio colaborativo en el que impulsar el aprovechamiento de la luz solar como fuente energética renovable, aportando y recibiendo conocimiento y participando en la experimentación para alcanzar soluciones novedosas que contribuyan al bienestar de nuestra comunidad desde el mimo al medio ambiente.</p>
							<p>También podrás comprobar que aspiramos a ser una comunidad reconocida por su experiencia, sostenibilidad, innovación, flexibilidad y transparencia.</p>
							<p>Que deseamos contribuir a alcanzar un <b>Nuevo Modelo Energético</b> que garantice la soberanía individual y comunitaria, a través de la generación distribuida y el autoconsumo, la independencia y seguridad del suministro, y que se rija por los principios de ahorro y la eficiencia energética respetando el entorno ecológico, social y cultural.</p>
							<p>En Candela, con las gentes de Candela, podrás hablar de lo que queremos ser y como serlo, de lo que vamos a hacer cada una de nosotras y cada uno de nosotros como miembros de esta comunidad, de lo que vamos a hacer con CANDELA y dentro de ella.</p>
							<p>Así sabrás que, más allá del respeto escrupuloso a la ley y a los Derechos Humanos, aspiramos a ser un espacio libre de violencias, un espacio en donde los cuidados mutuos sean la forma normal de actuar, entre las personas socias o clientes, las personas y entidades proveedoras, o las empleadas.</p>
							<p>Quédate en Candela, forma parte de nuestra comunidad cooperativizada y disfruta con nosotras y nosotros construyendo un entorno vivo y saludable.</p>
							{/* <a href="/" className="rm-btn el-btn-regular">Leer más</a> */}
						</Col>
						<Col md={5} sm={6} className="about-ds-image">
							<Image src={aboutimg} alt="" thumbnail />
						</Col>
					</Row>
				</Container>
			</section>

			<HomeLastNewsCarrousel />

			<Footer />
		</div>
	);
}

export default Home;
