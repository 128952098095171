import { useEffect, useState } from "react";

import { API } from "../../services/Api.js";
import Page from "./Page.js";

class PageWithDate {
  constructor({page, title, body, updated}) {
    this.page = page;
    this.title = title;
    this.body = body;
    this.updated = new Date(updated);
  }

  toObject() {
    return {
      page: this.page,
      title: this.title,
      body: this.body,
      updated: this.updated,
    }
  }
}

export default function PageFromApi({ pagename }) {
  const [page, setPage] = useState({
    page: pagename,
    title: "",
    body: "",
    updated: new Date(),
  });

  useEffect(() => {
    if (pagename) {
      API.pages.get(pagename)
        .then((response) => setPage(new PageWithDate(response).toObject()))
        .catch((err) => console.log(err))
    }
  }, [pagename]);

  useEffect(() => {
    document.title = page.title + ' | CandelaCoop';
  }, [page])

  return (
    <Page title={page.title}>
      <div>
        <div dangerouslySetInnerHTML={{__html: page.body}} />
        <div>Actualizado: {page.updated.toLocaleString()}</div>
      </div>
    </Page>
  );
}
