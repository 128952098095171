import React, { Component } from 'react';
import {
	EmailShareButton, EmailIcon,
	FacebookShareButton, FacebookIcon,
	LinkedinShareButton, LinkedinIcon,
	TelegramShareButton, TwitterIcon,
	TwitterShareButton, TelegramIcon,
	WhatsappShareButton,WhatsappIcon
} from "react-share";

export default class ShareThis extends Component {

	render() {

		const url = this.props.url;
		const title = this.props.title;

		return (

			<div className="blog-share">
				<ul className="list-unstyled">
					<li>Compártelo:</li>
					<li>
						<EmailShareButton
							url={url}
							title={title}
							separator=":: "
						>
							<EmailIcon size={32} round />
						</EmailShareButton>
					</li>
					<li>
						<FacebookShareButton
							url={url}
							quote={title}
						>
							<FacebookIcon size={32} round />
						</FacebookShareButton>
					</li>
					<li>
						<TwitterShareButton
							url={url}
							title={title}
						>
							<TwitterIcon size={32} round />
						</TwitterShareButton>
					</li>
					<li>
						<TelegramShareButton
							url={url}
							title={title}
						>
							<TelegramIcon size={32} round />
						</TelegramShareButton>
					</li>
					<li>
						<LinkedinShareButton
							url={url}
							title={title}
						>
							<LinkedinIcon size={32} round />
						</LinkedinShareButton>
					</li>
					<li>
						<WhatsappShareButton
							url={url}
							title={title}
							separator=":: "
						>
							<WhatsappIcon size={32} round />
						</WhatsappShareButton>
					</li>
				</ul>
			</div>
		)
	}
}
