import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import imageplaceholder from '../../assets/images/placeholder_image.png';


function HomeLastNewsCarrousel() {

	const [articles, setPosts] = useState(null);
	const [isLoading, setisLoading] = useState(true);
	const responsive = {
		desktop: {
			breakpoint: { max: 4000, min: 753 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 753, min: 0 },
			items: 1
		}
	};

	const loadingStyle = {
		textAlign: 'center',
		fontWeight: 'bold',
		padding: '15px',
		margin: 'auto',
		marginTop: '20%',
		width: 250,
		backgroundColor: '#a2bd30',
	};

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		const { carouselState: { currentSlide } } = rest;
		return (
			<div className="owl-nav">
				<div className={currentSlide === 0 ? 'owl-prev disable' : 'owl-prev'}>
					<FontAwesomeIcon icon={faAngleLeft} onClick={() => previous()} />
				</div>
				<div className="owl-next">
					<FontAwesomeIcon icon={faAngleRight} onClick={() => next()} />
				</div>
			</div>
		);
	};

	const fetchPost = async () => {
		const baseURL = process.env.REACT_APP_API_URL
		const response = await fetch(
			baseURL + 'news/?limit=10',
		);
		const data = await response.json();
		const post = data.data;
		setPosts(post);
		setisLoading(false);
	}

	useEffect(() => {
		fetchPost()
	}, []);

	if (isLoading) {
		return (
			<section className="blog-main courses08">
				<Container>
					<Row>
						<Col sm={12} className="course08-carousel owl-carousel">
							<h3 style={loadingStyle}>Loading...</h3>
						</Col>
					</Row>
				</Container>
			</section>
		)
	} else {

		function getExcerpt(content) {
			let excerpt
			let maxExcerpt = 100
			if (content.length > maxExcerpt) {
				excerpt = content.substring(0,maxExcerpt) + "... ";
			} else {
				excerpt = content;
			}
			let tmp = document.createElement("div");
			tmp.innerHTML = excerpt;
			return tmp.textContent || tmp.innerText || "";
		}

		const CarrouselItem = renderRows();

		function renderArticle(singleArticle) {
			return (
				<div key={singleArticle.id} className="course-single">
					<Row>
						<Col md={6} sm={12}>
							<div className="course-info">
								<h3>
									<a href={"/blog/" + singleArticle.attributes.slug + '/'}>
										{singleArticle.attributes.title}
									</a>
								</h3>
								{getExcerpt(singleArticle.attributes.body)}
								<ul className="list-unstyled course-bottom blog-bottom">

								</ul>
								<ul className="list-unstyled blog-tags">
									{singleArticle.attributes['tags'] ? (
										<React.Fragment>
											{singleArticle.attributes['tags'].map(tag =>
												<li key={tag.id}>
													<a href={"/tag/" + tag.attributes.slug + '/'} className="post-tag">#{tag.attributes.name}</a>
												</li>
											)}
										</React.Fragment>
									) : (
										""
									)}
								</ul>
							</div>
						</Col>
						<Col md={6} sm={12} className="course-image">
							<figure>
								{singleArticle.attributes.image_url ? (
									<img src={singleArticle.attributes.image_url} alt={singleArticle.attributes.title} className="img-responsive" />
								) : (
									<div className="blog-image">
										<img
											className="img-responsive webpic"
											alt="imagen destacada"
											src={imageplaceholder}
										/>
									</div>
								)}
								<figcaption>
									<div>
										<a href={"/blog/" + singleArticle.attributes.slug + '/'} className="el-btn-regular">Leer más</a>
									</div>
								</figcaption>
							</figure>
						</Col>
					</Row>
				</div>
			);
		}

		function renderRow(posts, i) {
			return (
				<div key={i} className="carousel-item">
					{posts}
				</div>
			);
		}

		function renderRows() {
			const news = articles;
			let posts = [];
			let rows = [];

			for (let i = 0; i < news.length; i++) {
				const singleArticle = news[i];
				// Accumulate items to add to the row
				posts.push(renderArticle(singleArticle));
				// when row have two items, new row
				if (i % 2 === 1) {
					rows.push(renderRow(posts, i));
					// Now empty array because still in new row
					posts = [];
				}
			}
			// The last item (if exist) to new row
			if (posts.length > 0) {
				rows.push(renderRow(posts, news.length));
			}
			return rows;
		}

		return (
			<section className="blog-main courses08">
				<Container>
					<Row>
						<Col sm={12} className="section-header04 section-header-d">
							<h2>Últimas <span>noticias</span></h2>
							<p>Gestiones, tarifas, factura de la luz... ¡Sigue todas nuestras novedades!</p>
							<span className="section-devider">
								<span><FontAwesomeIcon icon={faCircle} /></span>
							</span>
						</Col>
					</Row>
				</Container>

				<Container>
					<Row>
						<Col sm={12} className="course08-carousel owl-carousel">
							<Carousel
								arrows={false}
								renderButtonGroupOutside={true}
								customButtonGroup={<ButtonGroup />}
								responsive={responsive}
							>
								{CarrouselItem}
							</Carousel>
						</Col>
					</Row>
				</Container>

			</section>
		);

	}

}

export { HomeLastNewsCarrousel };
