import React, { Component } from 'react';
import Search from '../components/Search'

export default class Blog extends Component {

	constructor( props ) {
		super( props );
		this.state = {
			categories: [],
			tags: []
		}
	}

	componentDidMount() {
		const baseURL = process.env.REACT_APP_API_URL
		fetch(baseURL + 'news/categories/')
			.then(response => response.json())
			.then(jsonapi => this.setState({ categories: jsonapi.data }));

		fetch(baseURL + 'news/tags/')
			.then(response => response.json())
			.then(jsonapi => this.setState({ tags: jsonapi.data }));
	}

	render() {
		const { categories, tags } = this.state;

		return (
			<React.Fragment>
				<div className="blog-sidebar">
					<div className="sidebar-widget">
						<div className="widget-content search-widget">
							<Search />
						</div>
					</div>

					<div className="sidebar-widget">
						<h3>Categorías</h3>
						<div className="widget-content category-widget">
							<ul className="list-unstyled">
								{categories ? (
									<React.Fragment>
										{categories.map((category) =>
											<li key={category.id}>
												<a href={"/category/" + category.attributes.slug}>{category.attributes.name}</a>
											</li>
										)}
									</React.Fragment>
								) : (
									""
								)}
							</ul>
						</div>
					</div>
					<div className="sidebar-widget">
						<h3>Tags</h3>
						<div className="widget-content tags-widget">
							<ul className="list-unstyled">
								{tags ? (
									<React.Fragment>
										{tags.map((tag) =>
											<li key={tag.id}>
												<a href={"/tag/" + tag.attributes.slug}>{tag.attributes.name}</a>
											</li>
										)}
									</React.Fragment>
								) : (
									""
								)}
							</ul>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
