import { useEffect } from 'react';

const useScript = (url,async) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = url;
		script.async = async;

		document.body.appendChild(script);

		return () => {
		document.body.removeChild(script);
		}
	}, [url, async]);
};

// USE: useScript('/assets/revolution/jquery.themepunch.revolution.min.js', true);

export default useScript;
