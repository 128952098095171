import { ErrorMessage } from '@hookform/error-message';

export default function ErrorMessageMultiple({ errors, name}) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ messages }) =>
        messages && <span>{
          Object.entries(messages).map(([type, msg]) => (
            <div key={type} className="alert alert-danger">{msg}</div>
          ))
          }
        </span>
      }
    />
  )
}
