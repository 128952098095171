import React from "react";

import Card from 'react-bootstrap/Card';
import Vimeo from '@u-wave/react-vimeo';

function Candidate({candidate}) {
	return (
    <Card>
      <Card.Body>
        <Vimeo
          video={ candidate.src }
          responsive
        />
      </Card.Body>
      <Card.Body>
        <Card.Title>{ candidate.name }</Card.Title>
        <Card.Text dangerouslySetInnerHTML={{__html: candidate.info}}>
        </Card.Text>
      </Card.Body>
    </Card>
	)
}

export default Candidate;
