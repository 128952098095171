import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import ContactMap from '../components/ContactMap';
import ContactForm from '../components/ContactForm';


export default class Contact extends Component {

	render() {
    document.title = `${this.props.name} | CandelaCoop`;

		return (
			<div className="Contacto">
				<Header />
        <TitleBreadcrumbs name={this.props.name} />

				<section className="contact-wrapper">
					<Container>
						<Row>
							<ContactMap />
							<ContactForm />
						</Row>
					</Container>
				</section>

				<Footer />
			</div>
		)
	}
}
