import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import headerbg from '../../assets/images/bgheader.jpg';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BlogLastNews from "../components/BlogLastNews";
import ReactPaginate from 'react-paginate';
import Sidebar from "../components/Sidebar";
import { useLocation, useParams } from 'react-router-dom';

class ArchivesComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			data: [],
			perPage: 6,
			currentPage: 0
		};
		this.handlePageClick = this
			.handlePageClick
			.bind(this);
	}

	receivedData() {
		const baseURL = process.env.REACT_APP_API_URL

		let final_query
		switch (this.props.type) {
			case "Category":
				final_query = 'news/?categories=' + this.props.slug;
				this.setState({ title: "Entradas con la categoria " + this.props.slug })
				break;

			case "Tag":
				final_query = 'news/?tags=' + this.props.slug;
				this.setState({ title: "Entradas con la etiqueta " + this.props.slug })
				break;

			case "Results":
				final_query = `news/${this.props.location.search}`;
				this.setState({ title: "Resultados de búsqueda" })
				break;

			default:
				final_query = 'news/';
				this.setState({ title: "Noticias" })
				break;
		}
		fetch(baseURL + final_query)
			.then(response => response.json())
			.then(jsonapi => {

				const data = jsonapi.data;
				const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
				const postData = slice.map(pd =>
					<React.Fragment>
						<BlogLastNews key={pd.id} post={pd} />
					</React.Fragment>
				)

				this.setState({
					pageCount: Math.ceil(data.length / this.state.perPage),
					postData
				})
			});
	}
	handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * this.state.perPage;

		this.setState({
			currentPage: selectedPage,
			offset: offset
		}, () => {
			this.receivedData()
		});

	};

	componentDidMount(props) {
		document.title = 'Archivos | CandelaCoop';
		this.receivedData()
	}

	componentDidUpdate(prevProps) {
		// Only for searchs
		if(this.props.location !== undefined && this.props.location.search !== undefined) {
      if(this.props.location.search !== prevProps.location.search) {
        this.receivedData()
      }
		}
	}

	render() {
		return (
		<div className="blog-news">

			<Header />

			<section className="page-header"
			style={{ backgroundImage: `url(${headerbg})` }}>
				<Container>
					<Row>
						<Col sm={12}>
							<h1>{this.state.title}</h1>
							<ul className="list-unstyled">
								<li><a href="/">Inicio</a></li>
								<li className="active">{this.state.title}</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="blog-wrapper blog">
				<Container>
					<Row>
						<Col md={9} sm={8}>
							<div className="blog-four-content">
								<div className="blog-posts">
									<Row>
										{this.state.postData && this.state.postData.length ? (
											<React.Fragment>
												{this.state.postData}
											</React.Fragment>
										) : (
											<div className="alert alert-warning">No se han encontrado noticias</div>
										)}
									</Row>
								</div>
							</div>
								{this.state.postData && this.state.postData.length && (
									<div className="blog-pagination">
										<ReactPaginate
											previousLabel={"<"}
											nextLabel={">"}
											breakLabel={"..."}
											breakClassName={"break-me"}
											pageCount={this.state.pageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={this.handlePageClick}
											containerClassName={"list-unstyled"}
											subContainerClassName={"pages pagination"}
											activeClassName={"active"} />
									</div>
								)}
						</Col>
						<Col md={3} sm={4}>

							<Sidebar />

						</Col>
					</Row>
				</Container>
			</section>

			<Footer />

		</div>

		)
	}
}

const Archives = props => {
  const location = useLocation()
  const params = useParams()
  return <ArchivesComponent slug={params.slug} location={location} {...props} />
}

export default Archives;
