import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from "../components/Sidebar";
import ShareThis from '../components/ShareThis';

import headerbg from '../../assets/images/bgheader.jpg';
import imageplaceholder from '../../assets/images/placeholder_image.png';

import '../../assets/css/styles.css';
import { useParams } from 'react-router-dom';


const SingleArticle = () => {
  const { slug } = useParams();
	const [isLoading, setisLoading] = useState(true);
	const [post, setpost] = useState([]);

	const fetchPost = async () => {
		const baseURL = process.env.REACT_APP_API_URL
		const response = await fetch(
			`${baseURL}news/${slug}/`,
		);
		const post = await response.json();
		setpost(post);
		setisLoading(false);
	}

	useEffect(() => {
		fetchPost()
	}, []);

	const shareUrl = window.location.href;

	const loadingStyle = {
		textAlign: 'center',
		fontWeight: 'bold',
		padding: '15px',
		margin: 'auto',
		marginTop: '20%',
		width: 250,
		backgroundColor: '#a2bd30',
	};


	if (isLoading) {
		return (
			<div>
				<h3 style={loadingStyle}>Loading...</h3>
			</div>
		)
	} else {
		document.title = post.data.attributes.title + ' | CandelaCoop';
		return (
			<div className="Articulo">
        <meta property="og:title" content={post.data.attributes.title} />
        <meta property="og:image" content={post.data.attributes.image_url} />

				<Header />

				<section className="page-header"
				style={{ backgroundImage: `url(${headerbg})` }}>
					<Container>
						<Row>
							<Col sm={12}>
								<h1>{post.data.attributes.title}</h1>
								<ul className="list-unstyled">
									<li><a href="/">Inicio</a></li>
									<li className="active">{post.data.attributes.slug}</li>
								</ul>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="blog-wrapper blog">
					<Container>
						<Row>
							<Col md={9} sm={8}>
								<div className="blog-details-wrapper">
									{post.data.attributes.image_url ? (
										<div className="blog-image">
											<img
												className="img-responsive webpic"
												alt={post.data.attributes.title}
												src={post.data.attributes.image_url}
											/>
										</div>
									) : (
										<div className="blog-image">
											<img
												className="img-responsive webpic"
												alt="imagen destacada"
												src={imageplaceholder}
											/>
										</div>
									)}
									<div className="blog-meta">
										<h2>{post.data.attributes.title}</h2>
										<ul className="list-unstyled">
											{post.data.attributes.tags ? (
												<React.Fragment>
												<FontAwesomeIcon icon={faTags} />
													<li>
														{post.data.attributes.tags.map((tag) =>
														<span key={tag.id}>{tag.attributes.name}</span>)}
													</li>
												</React.Fragment>
											) : (
											""
											)}
										</ul>
									</div>
									<div className="blog-content">
										<div dangerouslySetInnerHTML={{__html: post.data.attributes.body}} />
									</div>

									<ShareThis url={shareUrl + '/'} title={post.data.attributes.title} />

								</div>

							</Col>
							<Col md={3} sm={4}>

								<Sidebar />

							</Col>

						</Row>
					</Container>
				</section>

				<Footer />

			</div>
		)
	}
}

export default SingleArticle
