import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';

import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import { API } from '../../services/Api';
import '../../assets/css/styles.css';
import '../../assets/css/vote.css';


function Vote(props) {
  document.title = `${props.name} | CandelaCoop`;
  const { slug } = useParams();

  const [sending, setSending] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues: {},
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setSending(true);
    setSuccessMessage("")
    setErrorMessages([])
    let processData = [];
    Object.entries(data).map(([key, value]) => {
      let options = [];
      if (Array.isArray(value)) {
        options = value.map(item => item.value);
      } else if (value !== "") {
        options = [value];
      }
      processData.push({
        question: key,
        options: options,
      })
    })

    API.voting.post(slug, processData)
      .then((response) => {
        setSuccessMessage("Votación enviada correctamente", response)
        reset();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return error.response.json();
        }
        if (error.response.status === 403) {
          return {"": ["No tiene permisos para hacer eso"]}
        }
        return {"": [error]}
      })
      .then((errJson) => {
        let errors = [];
        errJson && Object.entries(errJson).map(([field, msgs]) => {
          let msg = msgs;
          if (Array.isArray(msgs)) {
            msg = msgs[0]
          }
          errors.push({field: field, message: msg})
        })
        setErrorMessages(errors)
      })
      .finally(() => setSending(false))
  }

  const [voting, setVoting] = useState(null);

  useEffect(() => {
    API.voting.get(slug)
      .then(data => {
        setVoting(data)
      })
      .catch(error => alert("Error al cargar la votación", error))
  }, [slug])

	if (voting === null) {
		return (
			<div>
				<h3>...</h3>
			</div>
		)
	}

  return (
    <div className="Vote">
      <Header />
      <TitleBreadcrumbs name={props.name} />

      <section className="about-ds">
        <Container>
          <Row>
            <Col md={7} sm={6} className="about-ds-content">
              <div className="section-header03">
                <h2>{ voting.name }</h2>
                <span className="subtitle">{ voting.description }</span>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <ol>
                  { voting.questions.map((question, index) => {
                    const question_id = question.id.toString();
                    let options;
                    if (question.question_type === "D" && question.options.length > 0) {
                      options = (
                        <select className="question__options" id={question.id} {...register(question_id, { required: false })}>
                          <option key={`D${index}-empty`} value=""></option>
                          {question.options.map((option, key) =>
                            <option key={`${index}-${key}`} value={option.id}>
                              {option.name}
                            </option>
                          )}
                        </select>
                      );
                    } else if (question.question_type === "O") {
                      let question_options = question.options.map((option, key) => {
                        return { value: option.id, label: option.name }
                      });
                      options = (
                        <Controller
                          control={control}
                          name={question_id}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select className="question__options__check"
                              options={question_options}
                              onChange={onChange}
                              isMulti={true}
                              onBlur={onBlur}
                              noOptionsMessage="Seleccionar"
                              placeholder="Seleccionar"
                              value={value}
                              name={name}
                              ref={ref}
                            />
                          )}
                        />
                      )

                    } else if (question.question_type === "F") {
                      options = (
                        <div className="question__options">
                          <Row className="form-group">
                            <Col sm={12}>
                              <textarea {...register(question_id, { required: false, maxLength: 5000 })}>
                              </textarea>
                              <span>{formState.errors.message && <div className="alert alert-danger">El mensaje es obligatorio</div>}</span>

                            </Col>
                          </Row>
                        </div>
                      )
                    }

                    return (
                      <li key={index} className="question">
                        { question.name }
                        <div className="question__description">
                          { question.description }
                        </div>
                        { options }
                      </li>
                    )
                  })}
                </ol>

                <button className="mb-4" type="submit">
                  { sending ? "Votando ..." : "Votar" }
                </button>
                { successMessage && <div className="alert alert-success">{successMessage}</div> }
              </Form>

            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default Vote;
