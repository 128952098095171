import { Container, Col, Row } from "react-bootstrap";

import Header from "../components/Header.js";
import TitleBreadcrumbs from "../components/TitleBreadcrumbs.js";
import Footer from "../components/Footer.js";

export default function Page({ children, title }) {
  return (
    <div className={title}>
      <Header />
      <TitleBreadcrumbs name={title} />

      <section className="about-ds">
        <Container>
          <Row>
            <Col sm={12}>
              { children }
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />

    </div>
  );
}
