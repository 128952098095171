import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import AboutUs from './views/pages/About';
import Assembly from './views/pages/Assembly';
import Blog from './views/pages/Blog';
import Contact from './views/pages/Contact';
import Documents from './views/pages/Documents';
import Hire from './views/pages/Hire';
import Home from './views/pages/Home';
import Legal from './views/pages/Legal';
import Login from './views/pages/Login';
import NotFoundPage from './views/pages/NoMatch';
import Partner from './views/pages/Partner';
import Rates from './views/pages/Rates';
import Vote from './views/pages/Vote';

import Archives from './views/components/Archives';
import SingleArticle from './views/components/SingleArticle';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login/" element={<Login />} />
          <Route exact path="/blog/" element={<Blog name="Noticias" />} />
          <Route exact path="/quienes-somos/" element={<AboutUs name="Quiénes somos" />} />
          <Route exact path="/asamblea/" element={<Assembly name="Asamblea" />} />
          <Route exact path="/documentos/" element={<Documents name="Documentos" />} />
          <Route exact path="/tarifas/" element={<Rates name="Tarifas" />} />
          <Route exact path="/asociate/fisica/" element={<Partner mode="natural" name="Hazte socia/o" />} />
          <Route exact path="/asociate/juridica/" element={<Partner mode="legal" name="Hazte socia/o" />} />
          <Route exact path="/contacto/" element={<Contact name="Contacto" />} />
          <Route exact path="/legal/:slug" element={<Legal />} />
          <Route exact path="/blog/:slug" element={<SingleArticle />} />
          <Route exact path="/category/:slug" element={<Archives type="Category" />} />
          <Route exact path="/tag/:slug" element={<Archives type="Tag" />} />
          <Route exact path="/results/" element={<Archives type="Results" />} />
          <Route exact path="/hire/" element={<Hire name="Contrata Luz" />} />
          <Route exact path="/vote/:slug" element={<Vote name="Votación" />} />
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
