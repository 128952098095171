const baseURL = process.env.REACT_APP_API_URL;
const TOKEN_NAME = "[candela] token";

const headers = () => {
  const token = localStorage.getItem(TOKEN_NAME) ?? '';
  const _headers = new Headers();
  _headers.append('Accept', 'application/json');
  _headers.append('Content-Type', 'application/json');
  if (token) {
    _headers.append('Authorization', `Token ${token}`);
  }
  return _headers
}

async function fetchGet(path) {
  let data = { method: "GET", headers: headers() };
  return fetch(baseURL + path, data)
    .then((resp) => {
      if (!resp.ok) {
        let error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      }
      return resp.json();
    })
    .catch((error) => {
      //console.error("Error in the API call: ", error);
      throw error;
    });
}

async function fetchPost(path, data) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers(),
  };

  return fetch(baseURL + path, requestOptions)
    .then((response) => {
      if (response.status !== 201 && response.status !== 200) {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    })
    .catch(error => {
      //console.error("Error in the API call: ", error);
      throw error;
    })
}

async function fakeRequest(result, timeout = 1000) {
  return new Promise((resolve) => setTimeout(() => resolve(result), timeout));
}

function genQueryParams(object) {
  let queryParams = "?";
  for (let key in object) {
    let value = object[key];
    if (!value) {
      continue;
    }
    queryParams += `${key}=${value}&`;
  }
  return queryParams.substring(0, queryParams.length - 1);
}

var now = new Date();
var yesterday = new Date();
yesterday.setDate(now.getDate() - 1)

var tomorrow = new Date();
tomorrow.setDate(now.getDate() + 1)

const API = {
  members: {
    register: (data) => fetchPost("members/", data),
    me: (data) => fetchGet("members/me/", data),
    login: (data) => fetchPost("members/login/", data),
    hire: (data) => fetchPost("members/hire/", data),
  },
  pages: {
    list: () => fetchGet("pages/"),
    get: (name) => fetchGet(`pages/${name}/`),
  },
  documents: {
    get: () => fetchGet("documents/"),
  },
  assembly: {
    getDocuments: () => fetchGet("assembly/documents/"),
    getCandidates: () => fetchGet("assembly/candidates/"),
    getLiveVideo: () => fetchGet("assembly/live-video/"),
  },
  voting: {
    get: (slug) => fetchGet(`assembly/voting/${slug}/`),
    list: () => fetchGet("assembly/voting/"),
    post: (slug, data) => fetchPost(`assembly/voting/${slug}/answer/`, data),
  },
};

export {TOKEN_NAME, API};
