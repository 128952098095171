import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import NotFoundBG from '../../assets/images/404-bg.jpg';
import PageNotFound from '../../assets/images/404-image.png';


export default function NotFoundPage(params) {
	return (
		<div className="404">

			<Header/>

			<section className="page-header"
				style={{ backgroundImage: `url(${NotFoundBG})` }}>
				<Container>
					<Row>
						<Col sm={12}>
							<h1>Ups, no lo encontramos</h1>
							<ul className="list-unstyled">
								<li><a href="/">Inicio</a></li>
								<li className="active">404</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="about-ds">
				<Container>
					<Row>
						<Col md={7} sm={6} className="about-ds-content">
							<div className="section-header03">
								<h2>No encontramos lo que buscas</h2>
							</div>
							<p>Parece que lo que andas buscando no está por aquí.</p>
							<p>Quizás te apetezca usar el buscador del <Link to="/">blog </Link>, <Link to="/">ir a la home </Link> o navegar utilizando el menú superior</p>
						</Col>
						<Col md={5} sm={6} style={{ backgroundColor: "#a2bd30" }}>
							<img src={PageNotFound} alt="Not Found" className="img-responsive" />
						</Col>
					</Row>
				</Container>
			</section>

			<Footer />

		</div>
	);
}
