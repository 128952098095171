import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


export default class Search extends Component {
	constructor(props) {
		super(props)
		this.state = {
			redirect: false ,
			term: '',
		}
	}

	changeTerm = e => {
		this.setState({ term: e.target.value })
		this.setState({ redirect: false })
	}

	Submit = e => {
		e.preventDefault();
		this.setState({ term: encodeURI(this.state.term) })
		this.setState({ redirect: true })
	}

	render() {
		return (
			<React.Fragment>
				<form onSubmit={this.Submit}>
					<input
						placeholder="Buscador"
						onChange={this.changeTerm} />
					<button type="submit">
						<FontAwesomeIcon icon={faSearch} />
					</button>
				</form>

				{this.state.redirect &&
					<Navigate to={{
						pathname: "/results/",
						search: `?title=${this.state.term}`,
						state: { type: "Results", term: this.state.term }
					}} />
				}
			</React.Fragment>
		);
	}
}
