import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import { API } from '../../services/Api';


export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      documents: [],
    };
  }

  receivedData() {
    API.documents.get()
      .then(jsonapi => {
        const data = jsonapi.data;
        this.setState({
          text: data.text,
          documents: data.documents,
        })
      })
      .catch(error => alert("Error al carga los documentos"))
  }

  componentDidMount() {
    document.title = `${this.props.name} | CandelaCoop`;
    this.receivedData()
  }

  render() {
    return (
      <div className="Legal">
        <Header />
        <TitleBreadcrumbs name={this.props.name} />

        <section className="about-ds">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="section-header03">
                  <h2>Documentos</h2>
                </div>

                <div className="blog-content">
                  <div dangerouslySetInnerHTML={{__html: this.state.text}} />
                </div>

                { this.state.documents.length > 0 &&
                  <ul>
                    { this.state.documents.map((doc, index) =>
                      <li key={index}>
                          <a href={ doc.url }
                            title={ doc.name }>
                              { doc.name }
                          </a>
                      </li>
                    )}
                  </ul>
                }

              </Col>
            </Row>
          </Container>
        </section>

        <Footer />

      </div>
    )
  }
}
