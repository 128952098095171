export const file2base64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(extractMimeAndBase64(reader.result?.toString() || ''))
    };
    reader.onerror = error => reject(error);
  });
}

export const extractMimeAndBase64 = (encoded) => {
  const split = encoded.split(",");
  if (split.length !== 2) {
    return {mimetype: "", base64: ""}
  }

  return {
    mimetype: split[0].match(/:(.*?);/)[1],
    base64: split[1],
  }
}
