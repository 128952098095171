import React from 'react';
import { Accordion, Col, Container, Image, Row } from 'react-bootstrap';

import Header from '../components/Header';
import Footer from '../components/Footer';
import about01 from '../../assets/images/candela-sol.jpg';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import '../../assets/css/about.css';


function About(props) {
  document.title = `${props.name} | CandelaCoop`;

  return (
    <div className="About">
      <Header />

      <TitleBreadcrumbs name={props.name} />

      <section className="about-ds">
        <Container>
          <Row>
            <Col md={7} sm={6} className="about-ds-content">

              <div className="section-header03">
                <h2>¿Qué es Candela?</h2>
              </div>

              <p>CANDELA es una cooperativa  de consumo y producción energética que ha sido impulsada por diferentes colectivos sociales, políticos y sindicales de toda Andalucía para crear un espacio de confluencia social de base en la lucha por un <b>Nuevo Modelo Energético en nuestra tierra</b>. CANDELA tiene entre sus servicios, la producción y comercialización de energía proveniente de fuentes renovables y  el fomento del autoconsumo.</p>
              <p>En CANDELA Las personas y entidades trabajamos juntas para acelerar la <b>necesaria transición energética</b>, como sujetos en acción hacia un cambio democratizador del sistema eléctrico de nuestro país y así preservar el planeta. </p>
              <p>Esta transición energética consiste en crear un modelo que sea económica y políticamente más democrático, socialmente más justo y ecológicamente sostenible.</p>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    ¿Cuál es nuestro modelo energético?
                  </Accordion.Header>
                  <Accordion.Body>
                      <ul>
                        <li>Consideramos que la energía debe ser un <b>derecho humano</b>, asequible a toda la población, para cubrir las necesidades básicas de la vida.</li>
                        <li>Que la producción de energía tiene repercusiones importantes en la vida del planeta y sus Ecosistemas y, por tanto, debe ser racionalizada para no seguir deteriorándolo.</li>
                        <li>Que debemos impulsar las <b>energías limpias y renovables</b>, para promover un  nuevo modelo energético más respetuoso con el planeta.</li>
                        <li>Entendemos que la energía es un bien estratégico, cuyo <b>control</b> debe ser <b>popular</b>, y estar fuera de las lógicas del mercado.</li>
                        <li>Estamos en el convencimiento de que es  indispensable comenzar a poner en marcha <b>experiencias de gestión ciudadana orientadas al bien común</b>.</li>
                        <li><b>Con todo ello queremos participar en construir un modelo energético justo, democrático y sostenible</b>.</li>
                      </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    ¿Por qué una cooperativa energética andaluza?
                  </Accordion.Header>
                  <Accordion.Body>
                      <p>Creemos fundamental  que en Andalucía contemos con un espacio de acción  local donde se  luche para garantizar los derechos básicos en nuestro territorio, que fomente el uso de energías renovables y que contribuya  a fortalecer la economía local.</p>
                      <p>Nacemos para ser una <b>alternativa</b> a las empresas del <b>oligopolio eléctrico</b> facilitando que  cualquier persona, empresa, institución o familia andaluza, pueda contratar su suministro eléctrico a través de Candela  y de esta forma empezar a participar en la gestión de la energía que consume. </p>
                      <p>Por eso hemos construido  CANDELA, una <b>cooperativa andaluza para el consumo y la producción de energía limpia y 100% renovable</b>, sin ánimo de lucro, lo que significa que todos los beneficios o excedentes que podamos obtener por nuestra actividad, se reinvierten en la cooperativa o en la comunidad. </p>
                      <p>Queremos contar con la participación de todas las andaluzas y los andaluces que saben que otro modelo energético es posible y necesario, y también con el mayor número posible de organizaciones y colectivos que puedan compartir nuestros valores y nuestro deseo de caminar hacia una sociedad mejor.  Sabemos que la unión hace la fuerza, y entre todas y todas vamos a hacer realidad este cambio. </p>
                      <p>Nos parece imprescindible que Candela  sea un espacio de articulación social,  en torno a un <b>proyecto de transformación rea</b>l, que permita tejer redes más sólidas entre los colectivos y organizaciones, que pueda ser construido y defendido por todas en función de un interés común. </p>
                      <p>Nos gustaría que CANDELA se convierta en un espacio basado en la <b>erradicación de la cultura patriarcal</b> y la incorporación de las mujeres a la gestión energética.</p>
                      <p>Pretendemos generar un lugar de trabajo común, <b>de base, colectivo y participativo</b>.</p>
                      <p>Queremos participar en el impulso la <b>economía social</b> como un pilar básico del <b>desarrollo de Andalucía</b>, como movimiento socioeconómico de transformación y generadora de soberanía popular.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Nuestro modo de funcionamiento
                  </Accordion.Header>
                  <Accordion.Body>
                      <p>La incorporación a Candela supone hacerse socia o socio de pleno derecho, lo que da la posibilidad de <b>participar</b> en cada uno de sus espacios, y <b>decidir de manera directa</b>, en <b>Asamblea General</b>, sobre todas las decisiones importantes que afectan a la vida y los objetivos de la cooperativa. Es decir, como socia o socio, vas a poder tomar la decisión, junto con el resto de miembros, sobre cómo y de qué manera vamos a producir y consumir la energía que necesitamos.  El voto de cada persona socia tiene el mismo valor, por lo que el proceso es absolutamente democrático.</p>
                      <p>Pero además  en Candela sabemos que muchas veces la participación en un espacio que nos interesa no es solo una cuestión de voluntad, sino de oportunidad y posibilidad, es decir de que se den las condiciones oportunas. </p>
                      <p>Por eso en CANDELA contemplamos el articular todas las medidas necesarias para asegurar:</p>
                      <ul>
                        <li>Que los espacios de participación son respetuosos con la conciliación de la vida personal, familiar y laboral.</li>
                        <li>Que cada persona socia cuenta con la información necesaria para una toma de decisiones consciente y deliberada.</li>
                        <li>Que, con la ayuda de las compañeras y compañeros, el uso de las nuevas tecnologías no supondrá un factor de exclusión, sino facilitador de la participación.</li>
                      </ul>
                      <p>Además de la participación en las Asambleas Generales de la cooperativa, Candela tiene como propósito articular espacios mas directos y cotidianos de participación en cada territorio, que permita llevar una actividad y contacto continuo con el resto de compañeras y compañeros, en función de tus necesidades y apetencias. Son nuestras <b>COMUNIDADES ENERGÉTICAS</b>. En ellas podrás organizarte con el resto de cooperativistas de tu localidad para diseñar proyectos vecinales o comunitarios de autoconsumo, preparar campañas de sensibilización para otra forma de consumo eléctrico, o todas aquellas que consideréis oportunas. Las Comunidades energéticas son el corazón de Candela, porque conforman una red de activistas energéticos a lo largo y ancho de Andalucía.</p>
                      <p>Por último, recuerda que también contamos con el <b>Consejo Social</b>, espacio para dar cauce a la participación de colectivos y organizaciones que comparten nuestros objetivos y valores. El Consejo Social no es un órgano de decisión, sino que se constituye como <b>órgano consultivo y de asesoramiento</b> al Consejo Rector y a la Asamblea General. Será también un instrumento de participación, canalizando las iniciativas de carácter social y cooperativo que puedan surgir de las entidades, organizaciones y movimientos sociales miembros de la cooperativa.</p>
                      <p>Algunas de sus funciones específicas son:</p>
                      <ul>
                        <li>Velar por el respeto a los valores y principios fijados en el código ético de la cooperativa, por parte de todos los órganos y todas las personas socias.</li>
                        <li>Proponer las líneas de trabajo del Plan de Promoción Social y de todos aquellos planes que pudiera desarrollar la cooperativa con contenido social.</li>
                        <li>Supervisar las actividades y evolución previsible de la cooperativa en los aspectos sociales.</li>
                      </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </Col>
            <Col md={5} sm={6} className="about-ds-image">
              <Image src={about01} alt="" thumbnail />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />

    </div>
  )

}

export default About;
