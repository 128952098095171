import React, { useEffect, useState } from 'react';
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';

import Vimeo from '@u-wave/react-vimeo';

import Header from '../components/Header';
import TitleBreadcrumbs from '../components/TitleBreadcrumbs';
import Footer from '../components/Footer';
import Candidate from '../components/Candidate';
import { API } from '../../services/Api';
import '../../assets/css/assembly.css';


function Assembly(props) {
  document.title = `${props.name} | CandelaCoop`;

  const [votings, setVotings] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [liveVideos, setLiveVideos] = useState([]);


  useEffect(() => {
    API.voting.list()
      .then(data => {
        setVotings(data)
      })
      .catch(error => alert("Error al cargar las votaciones", error))

    API.assembly.getDocuments()
      .then(data => {
        setDocuments(data)
      })
      .catch(error => alert("Error al carga los documentos", error))

    API.assembly.getCandidates()
      .then(data => {
        setCandidates(data)
      })
      .catch(error => alert("Error al cargar candidatas", error))

    API.assembly.getLiveVideo()
      .then(data => {
        setLiveVideos(data)
      })
      .catch(error => alert("Error al cargar la retransmisión", error))
  }, [])

  return (
    <div className="Assembly">
      <Header />
      <TitleBreadcrumbs name={props.name} />

      { votings.length > 0 &&
        <section className="about-ds">
          <Container>
            <Row>
              <Col md={7} sm={6} className="about-ds-content">
                <div className="section-header03">
                  <h2>Votaciones</h2>
                </div>

                <ul>
                  { votings.map((vote, index) => {
                    let now = new Date();
                    let start = new Date(vote.start);
                    let end = new Date(vote.end);

                    const isOpen = start <= now && end >= now;

                    return (
                      <div key={index} className="votings">
                        { isOpen ? (
                            <a className="voting" href={ `/vote/${vote.slug}/` }>
                              <h3>{vote.name}</h3>
                              <p>
                                { start.toLocaleString() } - { end.toLocaleString() }
                              </p>
                              <p>
                                { vote.description }
                              </p>
                            </a>
                          ) : (
                            <OverlayTrigger
                              trigger="click"
                              placement="top"
                              overlay={
                                <Popover>
                                  <Popover.Header>
                                    <b>Votación no abierta</b>
                                  </Popover.Header>
                                  <Popover.Body>
                                    La votación solo estará disponible entre las fechas indicadas
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <div className="voting">
                                <h3>{vote.name}</h3>
                                <p>
                                  { start.toLocaleString() } - { end.toLocaleString() }
                                </p>
                                <p>
                                  { vote.description }
                                </p>
                              </div>
                            </OverlayTrigger>

                          )
                        }
                      </div>
                    )
                  })}
                </ul>

              </Col>
            </Row>
          </Container>
        </section>
      }

      { documents.length > 0 &&
        <section className="about-ds">
          <Container>
            <Row>
              <Col md={7} sm={6} className="about-ds-content">
                <div className="section-header03">
                  <h2>Documentos</h2>
                </div>

                <ul>
                  { documents.map((doc, index) =>
                  <li key={index}>
                    <a href={ doc.url } title={ doc.name }>
                      { doc.name }
                    </a>
                  </li>
                  )}
                </ul>

              </Col>
            </Row>
          </Container>
        </section>
      }

      { candidates.length > 0 &&
        <section className="about-ds">
          <Container>
            <Row>
              <Col sm={12} className="about-ds-content">
                <div className="section-header03">
                  <h2>Candidatas</h2>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="f-flex">
              { candidates.map((candidate, index) =>
                <Col key={index} className="p-3" xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Candidate candidate={candidate} />
                </Col>
              )}
            </Row>
          </Container>
        </section>
      }

      { liveVideos.length > 0 &&
        <section className="about-ds">
          <Container>
            <Row>
              <Col md={7} sm={6} className="about-ds-content">
                <div className="section-header03">
                  <h2>Sigue la asamblea en directo</h2>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="f-flex">
              { liveVideos.map((liveVideo, index) =>
                <Col key={index} className="p-3" xs={12} sm={12} md={6} lg={4} xl={3}>
                  <h3>{liveVideo.title}</h3>
                  <Vimeo
                    video={liveVideo.src}
                    responsive
                  />
                </Col>
              )}
            </Row>
          </Container>
        </section>
      }

      <Footer />
    </div>
  );
}

export default Assembly;
