import React from "react";
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imageplaceholder from '../../assets/images/placeholder_image.png';

const BlogLastNews = props => {
	const {
		// id = null,
		attributes = {}

	} = props.post || {};

	function getExcerpt(content) {
		let excerpt
		let maxExcerpt = 100
		if (content.length > maxExcerpt) {
			excerpt = content.substring(0,maxExcerpt) + "... ";
		} else {
			excerpt = content;
		}
		let tmp = document.createElement("div");
		tmp.innerHTML = excerpt;
		return tmp.textContent || tmp.innerText || "";
	}

	return (
		<Col sm={6}>
			<div className="single-blog">
				<figure>
					<div className="blog-thumb">
						{attributes.image_url ? (
							<Image src={attributes.image_url} alt={attributes.title} thumbnail />
						) : (
							<div className="blog-image">
								<Image
									className="webpic"
									alt="imagen destacada"
									src={imageplaceholder}
                  thumbnail
								/>
							</div>
						)}
					</div>
					<figcaption>
            {attributes['categories'].length > 0 ? (
							<a
								href={"/category/" + attributes['categories'][0].attributes.slug + '/'}
								className="post-category">
									{attributes['categories'][0].attributes.name}
							</a>
						) : (
							""
						)}
					</figcaption>
				</figure>
				<div className="blog-excerpt">
					<h3><a href={"/blog/" + attributes.slug + '/'}>{attributes.title}</a></h3>
					<p>{getExcerpt(attributes.body)}</p>
					<a href={"/blog/" + attributes.slug + '/'}>Leer más <FontAwesomeIcon icon={faArrowRight} /></a>
				</div>
			</div>
		</Col>
	)
}

BlogLastNews.propTypes = {
	post: PropTypes.shape({
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		attributes: PropTypes.shape({
			title: PropTypes.string.isRequired,
			body: PropTypes.string.isRequired,
			// featured_image: PropTypes.string.isRequired,
			slug: PropTypes.string.isRequired,
			categories: PropTypes.array,
			tags: PropTypes.array,
		}).isRequired
	}).isRequired
};

export default BlogLastNews;
